<template>
	<div :ref="`deliverItem${dataSource.id}`" class="deliverItem" @click="$emit('goDetail')">
		<div class="info">
			<!-- 标签 -->
			<Labels v-if="dataSource.orderLabel && dataSource.orderLabel.length > 0" :special="true" :specialData="orderLabels"></Labels>
			<div class="orderTitle-id mb-8">
				<span class="blod">{{dataSource.orderType === 3? dataSource.appOrderId: dataSource.dcosOrderId}}</span>
				<span class="status">
					{{ [dataInfo.taskCode, 4013] | codeFormat }}
				</span>
			</div>
			<div v-if="dataSource.customerName" class="name mb-8">
				{{ dataSource.customerName }}
			</div>
			<div class="tag mb-8">
				<div>
					{{ dataSource.customerType == 1 ? $t('个人') : $t('企业') }}
				</div>
				<template v-if="[1, 0].includes(dataSource.installmentsFlag)">
					<div class="gap"></div>
					<div>
						{{ dataSource.installmentsFlag == 1 ? $t('分期') : $t('全款') }}
					</div>
				</template>
				<template v-if="dataSource.bigType">
					<div class="gap"></div>
					<div class="bigTye">
						<!-- {{ dataSource.bigType == 1 ? $t('厂端大客户') : $t('代理商大客户') }} -->
						{{ $t('大客户') }}
					</div>
				</template>
			</div>
			<div v-if="carInfo" class="car-info mb-8" v-html="carInfo"></div>
			<div class="mb-8" style="color: rgba(13,23,26,0.45)">VIN： {{ dataSource.vin || '--' }}</div>
			<div class="follow-user">
				<span>{{ $t('交付专员') }}：</span>
				<span>
					{{
						dataSource.deliveryUserName || dataSource.deliveryPreUserName || '--'
					}}
				</span>
				<span>
					{{
						!dataSource.deliveryUserName && dataSource.deliveryPreUserName
							? `(${$t('预分配')})`
							: ''
					}}
				</span>
			</div>
			<ul class="options">
				<li
					v-if="dataSource.balancePayStatus && calculateCode(dataSource.balancePayStatus)"
					class="li"
					:class="{ 'li-4011003': dataSource.balancePayStatus == 4011003 }"
				>
					{{ dataSource.balancePayStatus | codeFormat }}
				</li>
				<li
					v-if="dataSource.invoiceStatus && calculateCode(dataSource.invoiceStatus)"
					class="li"
					:class="{ 'li-202402': dataSource.invoiceStatus == 202402 }"
				>
					{{ dataSource.invoiceStatus | codeFormat }}
				</li>
				<li
					v-if="dataSource.financeStatus && calculateCode(dataSource.financeStatus)"
					class="li "
					:class="{ 'li-40050012': dataSource.financeStatus == 40050012 }"
				>
					{{ dataSource.financeStatus | codeFormat }}
				</li>

				<li v-if="dataSource?.insureStatus" class="li 4002 insureStatus">{{ dataSource.insureStatus | insuranceCodeFormat }}</li>
			</ul>
		</div>
		<div v-if="isDeliverSalesOwner" class="bottom">
			<div class="operate">
				<!-- 逾期展示 -->
				<span v-if="$moment().diff(dataInfo.planTime, 'hours') > 1" class="overdue">
					{{$t('逾期时长')}}：{{ getYuQiTime(dataInfo.planTime) }}
				</span>
				<!-- 顾问自己的数据 展示跟进 -->
				<div v-if="isDeliverSalesOwner && dataInfo.taskCode !== 'DELIVERY_COST_APPLY'">
					<span v-if="dataInfo.taskCode === 'BOOK_VISIT'" class="followUp" @click.stop="confirmIntoStore">{{ $t('确认到店') }}</span>
					<span v-else class="followUp" @click.stop="goFollow">{{ $t('跟进填写') }}</span>
				</div>
			</div>
			<div v-if="isDeliverSalesOwner" class="icons">
				<!-- 未绑定微信时，展示【加微、绑定微信客户、拨号icon】 -->
				<!-- 绑定时，展示【企微会话、拨号icon】 -->
				<externalUserIcon v-if="!dataSource.customerWeChat" :id="dataSource.leadId" class="icon" @selectUser="selectUser"/>
				<qwIcon v-if="dataSource.customerWeChat" :leadId="dataSource.leadId" :externalUserIds="dataSource.customerWeChat" class="icon"/>
				<wxIcon v-if="!dataSource.customerWeChat" class="icon"/>
				<phoneIcon :phone="dataSource.customerMobile" class="icon"/>
			</div>
		</div>
	</div>
	</template>
<script>
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
import Labels from '@/components/card-view/label.vue'
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants/constants'
import { calculateCode } from '@/utils/index.js'
export default {
  components: {
    externalUserIcon,
    phoneIcon,
    qwIcon,
    wxIcon,
    Labels
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      dataSource: {},
      calculateCode
    }
  },
  computed: {
    ...mapGetters(['dictHash', 'userInfo']),
    orderLabels() {
      return this.dictHash[4009].filter(({ code }) => { return this.dataSource.orderLabel.includes(code) })
    },
    carInfo() {
      const {
        seriesName,
        modelName,
        colourName,
        interiorName,
      } = this.dataSource
      const arr = []
      if (seriesName) {
        arr.push(`<span>${seriesName}</span>`)
      }
      if (modelName) {
        arr.push(`<span>${modelName}</span>`)
      }
      if (colourName) {
        arr.push(`<span>${colourName}</span>`)
      }
      if (interiorName) {
        arr.push(`<span>${interiorName}</span>`)
      }
      return arr.join(
        '<span class="gap" style="width: 1px;margin: 0 8px;height: 12px;background-color: #d9d9d9;"></span>'
      )
    },
    // 是否交付店长
    isDeliverManage() {
      const { roleLogos=[] } = this.userInfo
      return roleLogos.includes(ROLES.DELIVERMANAGE)
    },
    // 是否是自己的数据
    isDeliverSalesOwner() {
      const { roleLogos=[], id } = this.userInfo
      return roleLogos.includes('1014006') && (this.dataSource.deliveryUserId == id || this.dataSource.authedDeliveryUserId == id)
    },
  },
  watch: {
    dataInfo: {
      handler(val) {
        if (val) {
          this.dataSource = val.deliveryRes
        }
      },
      immediate: true
    }
  },
  methods: {
    // 获取逾期展示时间
    getYuQiTime(planTime) {
      const diff = this.$moment().diff(planTime, 'seconds')
      // 将秒数转换为天和小时
      const days = Math.floor(diff / (24 * 3600))
      const hours = Math.floor((diff % (24 * 3600)) / 3600)

      const minutes = Math.ceil((diff % (24 * 3600)) % 3600 / 60) // 向上取整
      const str1 = days ? `${days} day ` : ''
      const str2 = hours ? `${hours} h ` : ''
      const str3 = minutes ? `${minutes} min` : ''
      return str1.concat(str2).concat(str3)
    },
    selectUser(params={}){
      const { externalUserid,name } = params
      this.$set(this.dataSource, 'customerName', name )
      this.$set(this.dataSource, 'customerWeChat', externalUserid)
    },
    // 跟进记录填写
    goFollow() {
      this.$router.push({
        path: '/deliver-follow',
        query: {
          id: this.dataSource.id
        }
      })
    },
    // 确认到店
    confirmIntoStore() {
      this.$router.push({
        path: '/bookInspection',
        query: {
          id: this.dataInfo.orderId
        }
      })
    }
  }
}
</script>
	<style lang="less" scoped>
	.deliverItem{
		background: #fff;
		box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
		border-radius: 8px;
		padding: 16px 12px 0px 12px;
		font-size: 13px;
		.van-ellipsis{
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.info{
			position: relative;
			padding-bottom: 12px;
			/deep/ .label-component{
				margin: 0 0 8px 0;
			}
			.mb-8 {
				margin-bottom: 8px;
			}
			.car-info {
				display: flex;
				align-items: center;
				color: rgba(13, 23, 26, 0.45);
			}
			.orderTitle-id {
				display: flex;
				align-items: center;
				padding-bottom: 12px;
				border-bottom: 1px solid rgba(13, 23, 26, 0.05);
				line-height: 16px;
				.blod {
					flex: 1;
				}
				.status {
					font-size: 14px;
					color: #029640;
				}
			}
			.tag {
				display: flex;
				align-items: center;
				color: #b9921a;
			}

			.gap {
				width: 1px;
				margin: 0 8px;
				height: 12px;
				background-color: #d9d9d9;
			}
			.options {
				display: flex;
				flex-wrap: wrap;
				margin-left: -4px;
				margin-right: -4px;
				.li {
					padding: 2px 8px;
					border-radius: 4px;
					border-width: 1px;
					border-style: solid;
					margin-top: 8px;
					margin-left: 4px;
					line-height: 16px;
					font-size: 12px;
					background: rgba(102,102,102,0.1);
					border: 1px solid rgba(102,102,102,0.1);
					&-4011003 {
						border-color: rgba(255, 125, 0, 0.1);
						color: #ff7d00;
						background: rgba(255, 125, 0, 0.1);
					}

					&-202402 {
						color: #029640;
						background: rgba(2, 150, 64, 0.1);
						border-color: rgba(2, 150, 64, 0.1);
					}
					&-40050012 {
						color: #b9921a;
						background: rgba(185, 146, 26, 0.1);
						border-color: rgba(185, 146, 26, 0.1);
					}
					&:last-child {
						margin-right: 4px;
					}
				}
			}
		}
		.bottom{
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid rgba(13,23,26,0.05);
			height: 53px;
			.operate{
				font-size: 13px;
				color: #0D171A;
				line-height: 20px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				flex: 1;
				display: flex;
				justify-content: space-between;
				.overdue{
					color: #e4002c;
				}
				.followUp{
					font-size: 14px;
					color: @yellow-text;
					// padding-left: 16px;
				}
			}
			.icons{
				display:flex;
				padding: 8px 0 12px 0;
				justify-content: space-between;
				.icon{
					margin-left: 16px;
					&:first-of-type {
						margin-left: 0;
					}
				}
			}
		}
		.check-item{
			position: absolute;
			right: 0px;
			top: calc(50% - 8px);
			z-index: 1;
			/deep/.van-checkbox__icon{
				font-size: 16px;
				.van-icon{
						border: 2px solid rgba(13,23,26,0.45);
				}
			}
			/deep/.van-checkbox__icon--checked .van-icon{
				background-color: #0D171A;
				border-color: #0D171A;
				line-height: 1;
			}
		}
	}
	</style>
	